import { useEffect, useState } from "react";
import './login.css'
import logoImg from '../kmtracker_Img/Borcelle.png'
import { useNavigate } from "react-router-dom";
import axios from "axios";
axios.defaults.withCredentials = true;
const serverid = '';


const ComponenteLogin = ()=>{
const [changeUser, setChangeUser] = useState('')
const [changePsw, setChangePsw] = useState('')

const naveLogin = useNavigate();

const handleChange= (e)=>{
    const user = e.target.value
    setChangeUser(user)
}

const handleChangePsw = (e)=>{
    const psw = e.target.value
    setChangePsw(psw)
}
useEffect(()=>{

    if (localStorage.getItem('usuario')){
        naveLogin("/InicialTracker");
    }
  },[naveLogin])

const handleEntrar = async (e)=>{
    e.preventDefault();
    try {
        const json = {
           usuario:changeUser,
           senha:changePsw
        };
        const buscarDados = await axios.post(serverid +'/AddCliente/auth',json);
        const result = await buscarDados.data
      document.cookie = `usuario=${changeUser}; expires=Thu, 01 Jan 2050 00:00:00 UTC; path=/;`;
      document.cookie = `token=${result.token}; expires=Thu, 01 Jan 2050 00:00:00 UTC; path=/;`;
      
      localStorage.setItem('usuario',changeUser);
      localStorage.setItem('token',result.token);
      naveLogin("/InicialTracker");
    } catch (error) {
        alert(error.response.data)
    }
   
}

    return (
    <>
        <div className="Login_contianer01">
            <div className="Login_telaPrincipal">
                
                <div
                style={{
                    height:'100px',
                    boxSizing: 'border-box',
                    border: '1px solid white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}  
                >
                     <img 
                     style={{width:'1005', height:'100%'}}
                     src={logoImg} alt="Descrição da Imagem" />

                </div>
                <form className="Login_form">
                    <div 
                    style={{
                        border: '1px solid white',
                        boxSizing: 'border-box',
                        padding: '10px',
                        position: 'relative',
                    }}
                    >
                        <label
                        style={{
                            position: 'absolute',
                            left: changeUser?'20px':'45px',
                            top: changeUser?'-8px':'14px',
                            color:'#505050',
                            pointerEvents: 'none',
                            transition: 'all 0.3s linear',
                            fontSize: changeUser?'12px':'15px',
                        }}
                        >Usuário</label>
                        <input 
                        style={{
                            backgroundColor:'rgb(240,240,240)',
                            width: '220px',
                            height: '30px',
                            textIndent:'32px',
                            borderRadius:'10px',
                            outline:'none',
                            border:'none',
                            boxShadow:'0 0 2px rgba(0,0,0,0.2)',
                        }}
                        onChange={handleChange}
                        value={changeUser}      
                        type="text">
                            
                        </input>
                        <div style={{
                            borderTopLeftRadius:'10px',
                            borderBottomLeftRadius:'10px',
                            width:'30px',
                            height:'32px',
                            display:'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            boxSizing: 'border-box',
                            position:'absolute',
                            top:'10px',
                            left:'10px',
                            backgroundColor:'#0075B4'
                        }}>
                        <svg style={{fill:'white',}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M123-232v-52q0-26 13.5-43.5t36.445-28.587Q222-379 269.5-393.5 317-408 391-408t121.5 14.5q47.5 14.5 96.555 37.413Q632-345 645.5-327.5T659-284v52H123Zm616 0v-52q0-32-10.947-59.982T697-392q23 6 45 15.5t45 20.5q23 11 36.5 30.16Q837-306.679 837-284v52h-98ZM391-512q-44.55 0-76.275-31.725Q283-575.45 283-620q0-44.55 31.725-76.275Q346.45-728 391-728q44.55 0 76.275 31.725Q499-664.55 499-620q0 44.55-31.725 76.275Q435.55-512 391-512Zm258-108q0 44.55-31.725 76.275Q585.55-512 541-512q18.321-22.763 28.161-50.505Q579-590.247 579-620.124 579-650 568.5-677 558-704 541-728q44.55 0 76.275 31.725Q649-664.55 649-620ZM151-260h480v-24q0-15-7.5-26T595-332q-42-23-90-35.5T391-380q-66 0-114 12.5T187-332q-21 11-28.5 22t-7.5 26v24Zm240-280q33 0 56.5-23.5T471-620q0-33-23.5-56.5T391-700q-33 0-56.5 23.5T311-620q0 33 23.5 56.5T391-540Zm0 280Zm0-360Z"/></svg>
                        </div>
                    </div>
                    <div 
                    style={{
                        border: '1px solid white',
                        boxSizing: 'border-box',
                        padding: '10px',
                        position: 'relative',
                        marginTop: '8px',
                    }}
                    >
                        <label
                        style={{
                            position: 'absolute',
                            left: changePsw?'20px':'45px',
                            top: changePsw?'-8px':'14px',
                            color:'#505050',
                            pointerEvents: 'none',
                            transition: 'all 0.3s linear',
                            fontSize: changePsw?'12px':'15px',
                        }}
                        >Senha</label>
                        <input 
                        style={{
                            width: '220px',
                            height: '30px',
                            textIndent:'32px',
                            borderRadius:'10px',
                            outline:'none',
                            border:'none',
                            backgroundColor:'rgb(240,240,240)',
                            boxShadow:'0 0 2px rgba(0,0,0,0.2)',
                        }}
                        onChange={handleChangePsw}
                        value={changePsw}      
                        type="password">
                            
                        </input>
                        <div style={{
                            borderTopLeftRadius:'10px',
                            borderBottomLeftRadius:'10px',
                            width:'30px',
                            height:'32px',
                            display:'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            boxSizing: 'border-box',
                            position:'absolute',
                            top:'10px',
                            left:'10px',
                            backgroundColor:'#0075B4'
                        }}>
                       <svg style={{fill:'white'}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M280-440q-17 0-28.5-11.5T240-480q0-17 11.5-28.5T280-520q17 0 28.5 11.5T320-480q0 17-11.5 28.5T280-440Zm0 148q-78 0-133-55T92-480q0-78 55-133t133-55q60 0 108 33.5t68 86.5h373l68 68-112 110-65-49-70 51-67-44H456q-20 52-68 86t-108 34Zm0-28q60 0 101.5-36t52.5-84h158l57 38 72-51 63 47 74-74-40-40H434q-11-48-52.5-84T280-640q-66 0-113 47t-47 113q0 66 47 113t113 47Z"/></svg>
                        </div>
                    </div>
                    <button
                    onClick={(e)=>{handleEntrar(e)}}
                    className="Login_btnEntrar">
                    <svg style={{marginRight:'8px', fill:'white'}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m438-456 170.308-170.308-28.539-28.538L438-513.077l-56-56-28.539 28.539L438-456Zm42 283.077q112.769-98.154 178.308-199.654 65.538-101.5 65.538-175.577 0-109.769-69.5-181.192T480-800.769q-104.846 0-174.346 71.423t-69.5 181.192q0 74.077 65.538 175.577Q367.231-271.077 480-172.923Zm0 53.692Q339-243.923 267.577-351.808q-71.423-107.884-71.423-196.346 0-126.923 82.654-209.385Q361.461-840 480-840t201.192 82.461q82.654 82.462 82.654 209.385 0 88.462-71.423 196.346Q621-243.923 480-119.231Zm0-436.154Z"/></svg>
                        Entrar
                    </button>
                </form>
            </div>
        </div>
    </>
    )
};

export default ComponenteLogin;