import './telainicial.css'
import AddCliente from '../adicionarCliente/Add_clientes';
import { useRef, useState, useEffect } from 'react';
import io from 'socket.io-client';
import axios from 'axios';
import carro from '../kmtracker_Img/carroVeiculo.png';
import placa from '../kmtracker_Img/Placa.png';
import moto from '../kmtracker_Img/moto.png';
import { useNavigate } from "react-router-dom";
const serverid = ''


const ComponenteTelaInicial = ()=>{
    const naveLogin = useNavigate();
   useEffect(()=>{ 
    if (!localStorage.getItem('usuario')){
        window.location.href = '/';
    }
  },[naveLogin])

    
   
const [addClient, setAddclient] = useState(false);
let [dadosIniciais, setDadosIniciais] = useState(false);

const handleAbrirAdduser = ()=>{
    setAddclient(true)
}


useEffect(() => {
    const socket = io(serverid);
    socket.disconnect();

   
    const dadosInicial = (data) => {
        setDadosIniciais(data);
        console.log(data)
    };
  
    socket.connect();
    socket.on('dadosInicial', dadosInicial);

    return () => {

      socket.disconnect();
    };

  }, []); // Dependência: socket
  const [configCliente,setConfigCliente]=useState(false)
  let [dadosVeiculoClick, setDadosVeiculoclick]=useState(false);
  const handleClickuser = async (e)=>{
    try {
        const id = e.currentTarget.id;
        const json = {
            id
        }
        const buscarDados = await axios.post(serverid +'/AddCliente/configCliente',json);
        const result = await buscarDados.data
        setDadosVeiculoclick(result)

        setConfigCliente(true)
    } catch (error) {
        if (error.status === 408){
            naveLogin("/")
            alert(error.reponse.data)
            return
        }
        
        console.log(error)
    }


  }

    return(
        <>
        {configCliente&&<ComponenteDadosCliente setConfigCliente={setConfigCliente} dados={dadosVeiculoClick}/>}
        {addClient&&<AddCliente addClient={addClient} setAddclient={setAddclient} />}
            <div className="incial_Container01"> 
            <div
            style={{margin:'10px', fontWeight:'bold',display:'flex', alignItems: 'center'}}
            >{dadosIniciais&&dadosIniciais['primeiroDados']['mes']}
            <svg style={{ marginLeft:'8px'}}xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 -960 960 960" width="22"><path d="M224.615-120Q197-120 178.5-138.5 160-157 160-184.615v-510.77Q160-723 178.5-741.5 197-760 224.615-760h70.769v-89.231h43.077V-760h286.154v-89.231h40.001V-760h70.769Q763-760 781.5-741.5 800-723 800-695.385v510.77Q800-157 781.5-138.5 763-120 735.385-120h-510.77Zm0-40h510.77q9.23 0 16.923-7.692Q760-175.385 760-184.615v-350.77H200v350.77q0 9.23 7.692 16.923Q215.385-160 224.615-160ZM200-575.385h560v-120q0-9.23-7.692-16.923Q744.615-720 735.385-720h-510.77q-9.23 0-16.923 7.692Q200-704.615 200-695.385v120Zm0 0V-720v144.615Zm280 181.539q-12.385 0-21.577-9.193-9.192-9.192-9.192-21.576 0-12.385 9.192-21.577 9.192-9.193 21.577-9.193 12.385 0 21.577 9.193 9.192 9.192 9.192 21.577 0 12.384-9.192 21.576-9.192 9.193-21.577 9.193Zm-160 0q-12.385 0-21.577-9.193-9.192-9.192-9.192-21.576 0-12.385 9.192-21.577 9.192-9.193 21.577-9.193 12.385 0 21.577 9.193 9.192 9.192 9.192 21.577 0 12.384-9.192 21.576-9.192 9.193-21.577 9.193Zm320 0q-12.385 0-21.577-9.193-9.192-9.192-9.192-21.576 0-12.385 9.192-21.577 9.192-9.193 21.577-9.193 12.385 0 21.577 9.193 9.192 9.192 9.192 21.577 0 12.384-9.192 21.576-9.192 9.193-21.577 9.193ZM480-240q-12.385 0-21.577-9.192-9.192-9.193-9.192-21.577 0-12.385 9.192-21.577 9.192-9.193 21.577-9.193 12.385 0 21.577 9.193 9.192 9.192 9.192 21.577 0 12.384-9.192 21.577Q492.385-240 480-240Zm-160 0q-12.385 0-21.577-9.192-9.192-9.193-9.192-21.577 0-12.385 9.192-21.577 9.192-9.193 21.577-9.193 12.385 0 21.577 9.193 9.192 9.192 9.192 21.577 0 12.384-9.192 21.577Q332.385-240 320-240Zm320 0q-12.385 0-21.577-9.192-9.192-9.193-9.192-21.577 0-12.385 9.192-21.577 9.192-9.193 21.577-9.193 12.385 0 21.577 9.193 9.192 9.192 9.192 21.577 0 12.384-9.192 21.577Q652.385-240 640-240Z"/></svg>
            </div>
                <div className='inicialmain_01'>
                    <div className='inicial_quadro01_main'>
                    <svg style={{fill:'#0075B4', position:'absolute', top:'7px', left:'7px'}} xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 -960 960 960" width="25"><path d="M560-440q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35ZM280-320q-33 0-56.5-23.5T200-400v-320q0-33 23.5-56.5T280-800h560q33 0 56.5 23.5T920-720v320q0 33-23.5 56.5T840-320H280Zm80-80h400q0-33 23.5-56.5T840-480v-160q-33 0-56.5-23.5T760-720H360q0 33-23.5 56.5T280-640v160q33 0 56.5 23.5T360-400Zm440 240H120q-33 0-56.5-23.5T40-240v-440h80v440h680v80ZM280-400v-320 320Z"/></svg>
                        <div style={{}}>

                        </div>
                        <div style={{display:'block'}}>
                            <div style={{width:'100%',boxSizing:'borderbox',fontWeight:'600', fontSize:'25px'}}>R$ {dadosIniciais&&dadosIniciais['primeiroDados']['pago']},00</div>
                            <div style={{fontSize:'13px', width:'100%', display:'flex', alignItems:'flexEnd', justifyContent: 'flex-end'}}>R$ {dadosIniciais&&dadosIniciais['primeiroDados']['valorTotal']},00</div>
                        </div>
                    <div
                    style={{fontSize:'clamp(13px,1.8vw,15px)',position: 'absolute',bottom: '5px', right: '8px'}}
                    >Recebimentos</div>
                    </div>


                    <div className='inicial_quadro01_main'>
                    <svg style={{fill:'#0075B4', position:'absolute', top:'7px', left:'7px'}} xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 -960 960 960" width="25"><path d="M400-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM80-160v-112q0-33 17-62t47-44q51-26 115-44t141-18h14q6 0 12 2-8 18-13.5 37.5T404-360h-4q-71 0-127.5 18T180-306q-9 5-14.5 14t-5.5 20v32h252q6 21 16 41.5t22 38.5H80Zm560 40-12-60q-12-5-22.5-10.5T584-204l-58 18-40-68 46-40q-2-14-2-26t2-26l-46-40 40-68 58 18q11-8 21.5-13.5T628-460l12-60h80l12 60q12 5 22.5 11t21.5 15l58-20 40 70-46 40q2 12 2 25t-2 25l46 40-40 68-58-18q-11 8-21.5 13.5T732-180l-12 60h-80Zm40-120q33 0 56.5-23.5T760-320q0-33-23.5-56.5T680-400q-33 0-56.5 23.5T600-320q0 33 23.5 56.5T680-240ZM400-560q33 0 56.5-23.5T480-640q0-33-23.5-56.5T400-720q-33 0-56.5 23.5T320-640q0 33 23.5 56.5T400-560Zm0-80Zm12 400Z"/></svg>
                        <div style={{}}>

                        </div>
                        <div style={{display:'block'}}>
                            <div style={{display:'flex',justifyContent:'center', width:'100%',boxSizing:'borderbox',fontWeight:'700', fontSize:'25px'}}>{dadosIniciais&&dadosIniciais['primeiroDados']['totais_cliente']}</div>
                            <div style={{fontSize:'13px', width:'100%', display:'flex', alignItems:'flexEnd', justifyContent: 'flex-end'}}>Clientes</div>
                        </div>
                    <div
                    style={{fontSize:'clamp(13px,1.8vw,15px)',position: 'absolute',bottom: '5px', right: '8px'}}
                    >Totais Cliente</div>
                    </div>



                    <div style={{backgroundColor:'rgba(250,210,0)'}} className='inicial_quadro01_main'>
                    <svg style={{fill:'#0075B4', position:'absolute', top:'7px', left:'7px'}} xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 -960 960 960" width="25"><path d="M680-80q-83 0-141.5-58.5T480-280q0-83 58.5-141.5T680-480q83 0 141.5 58.5T880-280q0 83-58.5 141.5T680-80Zm67-105 28-28-75-75v-112h-40v128l87 87Zm-547 65q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h167q11-35 43-57.5t70-22.5q40 0 71.5 22.5T594-840h166q33 0 56.5 23.5T840-760v250q-18-13-38-22t-42-16v-212h-80v120H280v-120h-80v560h212q7 22 16 42t22 38H200Zm280-640q17 0 28.5-11.5T520-800q0-17-11.5-28.5T480-840q-17 0-28.5 11.5T440-800q0 17 11.5 28.5T480-760Z"/></svg>
                        <div style={{}}>

                        </div>
                        <div style={{display:'block'}}>
                            <div style={{display:'flex',justifyContent:'center', width:'100%',boxSizing:'borderbox',fontWeight:'700', fontSize:'28px'}}>{dadosIniciais&&dadosIniciais['primeiroDados']['pagamento_pendente']}</div>
                            
                        </div>
                    <div
                    style={{fontSize:'clamp(15px,1.8vw,15px)',position: 'absolute',bottom: '5px', right: '8px'}}
                    >Pg. Pendente</div>
                    </div>

                    <div  style={{backgroundColor:'rgba(245,50,50)'}} className='inicial_quadro01_main'>
                    <svg style={{fill:'white', position:'absolute', top:'7px', left:'7px'}} xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 -960 960 960" width="25"><path d="M620-520q25 0 42.5-17.5T680-580q0-25-17.5-42.5T620-640q-25 0-42.5 17.5T560-580q0 25 17.5 42.5T620-520Zm-280 0q25 0 42.5-17.5T400-580q0-25-17.5-42.5T340-640q-25 0-42.5 17.5T280-580q0 25 17.5 42.5T340-520Zm140 100q-68 0-123.5 38.5T276-280h66q22-37 58.5-58.5T480-360q43 0 79.5 21.5T618-280h66q-25-63-80.5-101.5T480-420Zm0 340q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-400Zm0 320q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Z"/></svg>
                        <div style={{}}>

                        </div>
                        <div style={{display:'block', color:'white'}}>
                            <div style={{display:'flex',justifyContent:'center', width:'100%',boxSizing:'borderbox',fontWeight:'600', fontSize:'28px'}}>{dadosIniciais&&dadosIniciais['primeiroDados']['pagamento_atrasado']}</div>
                            
                        </div>
                    <div
                    style={{color:'white',fontSize:'clamp(15px,1.8vw,15px)',position: 'absolute',bottom: '5px', right: '8px'}}
                    >Pg. Atrasado</div>
                    </div>
                
                </div>

                <div className='inicialmain_02'>
                {dadosIniciais && Object.keys(dadosIniciais['sugundoDados']).map((key, index) => (
                    <div
                    key ={index}
                    id={dadosIniciais['sugundoDados'][key]['cpf']}
                    onClick={(e)=>{handleClickuser(e)}}
                    style={{
                        borderBottom:
                          dadosIniciais['sugundoDados'][key]['situacao'] === 'PENDENTE'
                            ? '4px solid rgb(255,210,100)'
                            : dadosIniciais['sugundoDados'][key]['situacao'] === 'PAGO'
                            ? '4px solid green'  // Adicione aqui o estilo para PAGO
                            : dadosIniciais['sugundoDados'][key]['situacao'] === 'ATRASADO'
                            ? '4px solid red'   // Adicione aqui o estilo para ATRASADO
                            : '4px solid transparent'  // Adicione aqui o estilo padrão ou para outros casos
                      }}
                    className='container_Clientes'>
                    <div
                    style={{
                        
                        display:'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      
                    }}
                    >
                    <div
                    style={{display:'flex',
                    width:'100%',
                 
                }}
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z"/></svg>
                    <div style={{fontWeight:'600', marginLeft:'5px'}}>{key}</div>
                    </div>

                    <div
                    style={{display:'flex',
                    width:'50%',
                     justifyContent: 'center',alignItems: 'center'
                }}
                    >

                    <div
                    style={{
                        width:'35px', height:'35px',
                        borderRadius:'100%',
                        backgroundColor:'rgba(0,0,0,0.12)',
                        display:'flex',justifyContent: 'center',alignItems:'center',
                        fontWeight:'600',
                    }}
                    >
                       {dadosIniciais['sugundoDados'][key]['qtdVeiculo']}
                    </div>
                    </div>

                    <div style={{width:'30%', display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z"/></svg>
                    </div>
                    
                    </div>
                    
                    <div style={{display:'flex', alignItems:'center', marginTop:'10px'}}>
                    <svg style={{marginRight:'5px'}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M444-200h70v-50q50-9 86-39t36-89q0-42-24-77t-96-61q-60-20-83-35t-23-41q0-26 18.5-41t53.5-15q32 0 50 15.5t26 38.5l64-26q-11-35-40.5-61T516-710v-50h-70v50q-50 11-78 44t-28 74q0 47 27.5 76t86.5 50q63 23 87.5 41t24.5 47q0 33-23.5 48.5T486-314q-33 0-58.5-20.5T390-396l-66 26q14 48 43.5 77.5T444-252v52Zm36 120q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                    R$ {dadosIniciais['sugundoDados'][key]['valortotal_devedor']},00
                        <div style={{marginLeft:'18px',display:'flex', alignItems:'center'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m388-212-56-56 92-92-92-92 56-56 92 92 92-92 56 56-92 92 92 92-56 56-92-92-92 92ZM200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Z"/></svg>
                        {dadosIniciais['sugundoDados'][key]['dataPagamento']}</div>
                    </div>
                </div>
                ))}
                    

            

                 
             
                </div>

                <div
                onClick={()=>{handleAbrirAdduser()}}
                style={{
                    zIndex:'10',bottom:'10px',right:'10px',borderRadius:'100%',
                    display:'flex', justifyContent:'center', alignItems:'center',
                    fontSize:'40px',backgroundColor:'#0075B4',color:'white',
                    position: 'fixed', width:'50px', height:'50px'}}
                >
                    +
                </div>
            </div>
        </>
    )
}

const ComponenteDadosCliente = ( { setConfigCliente, dados } )=>{
    const naveLogin = useNavigate();
    const textoRef = useRef(null);
    const copiarParaClipboard = () => {
        // Seleciona o conteúdo a ser copiado
        textoRef.current.select();
        document.execCommand('copy');
      };
    const [fecharAdd, setFecharAdd] = useState(false)
    const handleFechar = ()=>{
        setFecharAdd(true)
        setTimeout(() => {
            setConfigCliente(false)
        }, 210);
    }
    const handleConfirmarPagamento = async ()=>{
        try {
            const json = {
                cpf:dados['cpf']
            }
            const buscarDados = await axios.post(serverid +'/AddCliente/confirmarPagamento',json);
            const result = await buscarDados.data
            alert(result)
        } catch (error) {
            
    if (error.status === 408){
        naveLogin("/")
        return
    }
            alert(error.response.data)
            console.log(error)
        }
    }

    const handlePendentePAgamento= async ()=>{
        try {
            const json = {
                cpf:dados['cpf']
            }
            const buscarDados = await axios.post(serverid +'/AddCliente/addpendente',json);
            const result = await buscarDados.data
            alert(result)
        } catch (error) {
            if (error.status === 408){
                naveLogin("/")
                return
            }
            alert(error.response.data)
            console.log(error)
        }
    }
    const handleExcluir = async (e)=>{
        try {
            const iD = e.target.id
            let [cpf,chave] = iD.split(' ')
            const json = {
                cpf:cpf,
                key:chave
            }
            const buscarDados = await axios.post(serverid +'/AddCliente/excluirVeiculo',json);
            const result = await buscarDados.data
            alert(result)
        } catch (error) {
            alert(error.response.data)
        }
    }
    return(
        <>
            <div className={`Addcliente_containerBlack ${!fecharAdd?'BlackOpen':'closekOpen'}`}>
                <div className={`addclient_white ${!fecharAdd?'whiteOpen':'whiteClose'}`}>
                <h3>Dados do veiculo</h3>
                <svg onClick={()=>{handleFechar()}} style={{position:'absolute',top:'15', right:'15px'}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                <div style={{position:'relative',boxSizing:'border-box',flexDirection:'row',display:'flex',alignItems:'center',marginBottom:'10px',width:'100%', height:'42px'}}>
                {dados['situacao'] === 'PAGO' ? (
  <>
    <svg style={{ fill: 'rgb(50,215,100)' }} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
      <path d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
    </svg>
    <span style={{ color: 'rgb(50,215,100)', fontWeight: '600' }}>PAGO</span>
  </>
) : dados['situacao'] === 'ATRASADO' ? (
  <>
    <svg style={{ fill: 'rgb(215,50,100)' }} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
      <path d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
    </svg>
    <span style={{ color: 'rgb(215,50,100)', fontWeight: '600' }}>ATRASADO</span>
  </>
) : dados['situacao'] === 'PENDENTE' ? (
  <>
    <svg style={{ fill: 'rgb(255,150,100)' }} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
      <path d="m344-60-76-128-144-32 14-148-98-112 98-112-14-148 144-32 76-128 136 58 136-58 76 128 144 32-14 148 98 112-98 112 14 148-144 32-76 128-136-58-136 58Zm34-102 102-44 104 44 56-96 110-26-10-112 74-84-74-86 10-112-110-24-58-96-102 44-104-44-56 96-110 24 10 112-74 86 74 84-10 114 110 24 58 96Zm102-318Zm0 200q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240Z" />
    </svg>
    <span style={{ color: 'rgb(255,150,100)', fontWeight: '600' }}>PENDENTE</span>
  </>
) : null}

                    
                    
                    
                    <div>
                    {dados['situacao'] !== 'PAGO' ? 
                         <button
                         onClick={handleConfirmarPagamento}
                         className='btns'
                         >Confirmar Pagamento</button>
                         :<button
                         onClick={handlePendentePAgamento}
                         className='btnsRed'
                         >Add. Pendente</button>
                    }
                   

                    
                    </div>
                    
                </div>
                <div>

{dados && Object.keys(dados['cliente']).map((key, index) => (
    dados && Object.keys(dados['cliente'][key]).map((key2, index2) => (

                <div key={index2} style={{border:dados['situacao']!=='PAGO'?'2px solid red':'2px solid rgba(50,230,100)'}} className='DadosVeiculo'>
                    <div
                style={{
                    height:'25px',
                    boxSizing: 'border-box',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}  
                >
                <svg id={dados&&dados['cpf'] + ' ' + dados['cliente'][key][key2]['chaveKey']} onClick={(e)=>{handleExcluir(e)}}  style={{fill:'red', position:'absolute', top:'5px', right:'5px', boxShadow:'0 5px 8px rgba(220,100,0,0.3)', padding:'3px', borderRadius:'5px'}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg>
                 {dados['cliente'][key][key2]['tipo_Veiculo']!=='MOTO'?<img /* aqui realizar a veriica para ver se e moto ou carro */
                     style={{width:'45', height:'100%'}}
                     src={carro} alt="Descrição da Imagem" />:
                     <img 
                     style={{width:'35', height:'100%'}}
                     src={moto} alt="Descrição da Imagem" />}

                    <div style={{fontSize:'15px',fontWeight:'600',marginLeft:'5px'}}>{dados['cliente'][key][key2]['veiculo']}</div>
                    
                    <img
                    
                     style={{width:'35', height:'100%', marginLeft:'5px'}}
                     src={placa} alt="Descrição da Imagem" />
                    <div style={{fontSize:'15px',fontWeight:'600',marginLeft:'5px'}}>{dados['cliente'][key][key2]['placa']}</div>
                    
                    
                    </div>
                    
                    <div style={{flexDirection:'row',display:'flex',marginTop:'7px',boxSizing:'border-box',width:'100%'}}>
                        <div className='ParametrosVeiculos'><div>Nº Imei: </div><div className='spnvalor'>{dados['cliente'][key][key2]['imei']}</div></div>
                        <div className='ParametrosVeiculos'><div>Nº Sim: </div><div className='spnvalor'>{dados['cliente'][key][key2]['Sim']}</div></div>
                        <div className='ParametrosVeiculos'><div>Equip. </div><div className='spnvalor'>{dados['cliente'][key][key2]['equip']}</div></div>
                    </div>
                    <div style={{flexDirection:'row',display:'flex',marginTop:'7px',boxSizing:'border-box',width:'100%'}}>
                        <div style={{justifyContent:'flex-start',}} className='ParametrosVeiculos'><div></div>Observação<div style={{border:'1px solid rgba(0,0,0,0.3)', padding:'5px'}} className='spnvalor'>{dados['cliente'][key][key2]['Observacao']}</div></div>
                        
                    </div>

                    
                </div>  
                ))
              ))}  
                  
                </div>
                <hr style={{marginTop:'20px', marginBottom:'20px'}}></hr>

                <div style={{alignItems:'center',marginBottom:'12px', fontSize:'15px', display:'flex', position:'relative'}}>
                    Sugestão para notificação!
                    <button
                    onClick={copiarParaClipboard}
                    className='btnCopy'>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M360-240q-33 0-56.5-23.5T280-320v-480q0-33 23.5-56.5T360-880h360q33 0 56.5 23.5T800-800v480q0 33-23.5 56.5T720-240H360Zm0-80h360v-480H360v480ZM200-80q-33 0-56.5-23.5T120-160v-560h80v560h440v80H200Zm160-240v-480 480Z"/></svg>
                        Copiar</button>
                    </div>
                    <div style={{fontSize:'12px',padding:'5px',border:'1px solid rgba(0,0,0,0.15)'}} ref={textoRef}>
                <p>👋 Olá {dados&&dados['nome']},</p>
    <p>Esta notificação é referente ao pagamento do rastreador no valor de R$ {dados&&dados['valor']},00, com vencimento em {dados&&dados['vencimento']}.</p>
    <p>ℹ️ <strong>Detalhes do Pagamento:</strong></p>
    <ul>
        <li>Valor: R$ {dados&&dados['valor']},00</li>
        <li>Vencimento: {dados&&dados['vencimento']}</li>
        <li>Status: {dados&&dados['situacao']}</li>
    </ul>
    <p>📌 <strong>Instruções para Pagamento:</strong></p>
    <p>Por favor, utilize a chave Pix fornecida abaixo para efetuar o pagamento:</p>
    <p>🔐 <strong>Chave Pix: mauricio01.araujo@gmail.com</strong></p>
    <p>Agradecemos pela sua atenção e pontualidade no pagamento. Caso já tenha efetuado o pagamento, por favor, desconsidere esta mensagem.</p>
    <p>Qualquer dúvida, estamos à disposição.</p>
    <p>Atenciosamente,<br></br>[Mauricio]</p>

                </div>

                <textarea
        ref={textoRef}
        readOnly
        style={{ position: 'absolute', left: '-9999px', height: '1px', width: '1px', overflow: 'hidden' }}
        value={`👋 Olá ${dados && dados['nome']},

Esta notificação é referente ao pagamento do rastreador no valor de **R$ ${dados && dados['valor']},00,** com vencimento em **${dados && dados['vencimento']}.**

ℹ️ **Detalhes do Pagamento:**

- **Valor:** R$ ${dados && dados['valor']},00
- **Vencimento:** ${dados && dados['vencimento']}
- **Status:** ${dados && dados['situacao']}

📌 **Instruções para Pagamento:**

Por favor, utilize a chave Pix fornecida abaixo para efetuar o pagamento:

🔐 **Chave Pix: mauricio01.araujo@gmail.com**

Agradecemos pela sua atenção e pontualidade no pagamento. Caso já tenha efetuado o pagamento, por favor, desconsidere esta mensagem.
Qualquer dúvida, estamos à disposição.

Atenciosamente,
[Mauricio]`}
      />
                </div>
            </div>
       </>    
    )
}

export default ComponenteTelaInicial