import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';
import Login from './LoginScren/login';
import TelaInicial from './inicialTracker/telaInicialTracker'

function App() {
  return (
   <>
    <div>
    <div className="routerClass">
      <Router>
        <Routes>
          <Route path="/" element={<Login  />} />
          <Route path="/InicialTracker" element={<TelaInicial  />} />
        </Routes>
      </Router>
      
    </div>
    </div>
   </>
  );
}

export default App;
