import './Add_clientes.css'
import { useState } from 'react'
import axios from 'axios';
const serverid = ''
const ComponenteAddCliente = ( { addClient, setAddclient } )=>{
    let [jsonClient, setjsonClient] = useState({
        cpf:'',
        email:'',
        nome:'',
        cidade:'',
        telefone:'',
        veiculo:'',
        n_placa:'',
        tp_veiculo:'',
        equipamento:'',
        imei:'',
        numero_sim:'',
        data_Instalacao:'',
        valor:'',
        tipo_aquisicao:'',
        vencimento:'',
        obs:'',
        })

    const handleChange = (e)=>{
        const select = e.target.id
        setjsonClient((prevJsonClient) => ({
            ...prevJsonClient,
            [select]: e.target.value,
          }));
    }

const [fecharAdd, setFecharAdd] = useState(false)
    const handleFechar = ()=>{
        setFecharAdd(true)
        setTimeout(() => {
            setAddclient(false)
        }, 210);
    }

const handleRegistrar = async (e)=>{
    e.preventDefault()
try {
    const buscarDados = await axios.post(serverid +'/AddCliente/registrarCliente',jsonClient)
    alert(buscarDados.data)
} catch (error) {
    alert(error.response.data)
    console.log(error)
}
}
    return(
        <>
            <div className={`Addcliente_containerBlack ${!fecharAdd?'BlackOpen':'closekOpen'}`}>
                <div className={`addclient_white ${!fecharAdd?'whiteOpen':'whiteClose'}`}>
                    <div>
                        <h3>Adicione Aclientes</h3>
                    </div>
                <svg onClick={()=>{handleFechar()}} style={{position:'absolute',top:'15', right:'15px'}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                    <form className='addCliente_Form'>
                        <fieldset>
                            <legend>Dados Cliente</legend>
                            <div className='inputStyle'>
                                <label style={{
                                    transition:'all 0.25s ease-in-out',
                                    top:jsonClient.cpf?'-13px':'3px',
                                    left:jsonClient.cpf?'0px':'8px',
                                    fontSize:jsonClient.cpf?'12px':'15px',
                                    fontWeight:jsonClient.cpf?'700':'400',
                                    color:jsonClient.cpf?'rgba(0,0,0,0.9)':'rgba(0,0,0,0.5)',
                                    }}>CPF</label>
                                <input
                                id = 'cpf'
                                value={jsonClient.cpf}
                                onChange={(e)=>{handleChange(e)}}
                                type='number'></input>
                            </div>

                            <div className='inputStyle'>
                                <label style={{
                                    transition:'all 0.25s ease-in-out',
                                    top:jsonClient.email?'-13px':'3px',
                                    left:jsonClient.email?'0px':'8px',
                                    fontSize:jsonClient.email?'12px':'15px',
                                    fontWeight:jsonClient.email?'700':'400',
                                    color:jsonClient.email?'rgba(0,0,0,0.9)':'rgba(0,0,0,0.5)',
                                    }}>E-mail</label>
                                <input
                                id = 'email'
                                value={jsonClient.email}
                                onChange={(e)=>{handleChange(e)}}
                                type='email'></input>
                            </div>

                            <div className='inputStyle'>
                                <label style={{
                                    transition:'all 0.25s ease-in-out',
                                    top:jsonClient.nome?'-13px':'3px',
                                    left:jsonClient.nome?'0px':'8px',
                                    fontSize:jsonClient.nome?'12px':'15px',
                                    fontWeight:jsonClient.nome?'700':'400',
                                    color:jsonClient.nome?'rgba(0,0,0,0.9)':'rgba(0,0,0,0.5)',
                                    }}>Nome</label>
                                <input
                                id = 'nome'
                                value={jsonClient.nome}
                                onChange={(e)=>{handleChange(e)}}
                                type='text'></input>
                            </div>

                            <div className='inputStyle'>
                                <label style={{
                                    transition:'all 0.25s ease-in-out',
                                    top:jsonClient.cidade?'-13px':'3px',
                                    left:jsonClient.cidade?'0px':'8px',
                                    fontSize:jsonClient.cidade?'12px':'15px',
                                    fontWeight:jsonClient.cidade?'700':'400',
                                    color:jsonClient.cidade?'rgba(0,0,0,0.9)':'rgba(0,0,0,0.5)',
                                    }}>Cidade</label>
                                <input
                                id = 'cidade'
                                value={jsonClient.cidade}
                                onChange={(e)=>{handleChange(e)}}
                                type='text'></input>
                            </div>

                            <div className='inputStyle'>
                                <label style={{
                                    transition:'all 0.25s ease-in-out',
                                    top:jsonClient.telefone?'-13px':'3px',
                                    left:jsonClient.telefone?'0px':'8px',
                                    fontSize:jsonClient.telefone?'12px':'15px',
                                    fontWeight:jsonClient.telefone?'700':'400',
                                    color:jsonClient.telefone?'rgba(0,0,0,0.9)':'rgba(0,0,0,0.5)',
                                    }}>Telefone</label>
                                <input
                                id = 'telefone'
                                value={jsonClient.telefone}
                                onChange={(e)=>{handleChange(e)}}
                                type='text'></input>
                            </div>
                        </fieldset>


                        <fieldset>
                            <legend>Dados Veículo</legend>
                            <div className='inputStyle'>
                                <label style={{
                                    transition:'all 0.25s ease-in-out',
                                    top:jsonClient.veiculo?'-13px':'3px',
                                    left:jsonClient.veiculo?'0px':'8px',
                                    fontSize:jsonClient.veiculo?'12px':'15px',
                                    fontWeight:jsonClient.veiculo?'700':'400',
                                    color:jsonClient.veiculo?'rgba(0,0,0,0.9)':'rgba(0,0,0,0.5)',
                                    }}>Veiculo</label>
                                <input
                                id = 'veiculo'
                                value={jsonClient.veiculo}
                                onChange={(e)=>{handleChange(e)}}
                                type='text'></input>
                            </div>

                            <div className='inputStyle'>
                                <label style={{
                                    transition:'all 0.25s ease-in-out',
                                    top:jsonClient.n_placa?'-13px':'3px',
                                    left:jsonClient.n_placa?'0px':'8px',
                                    fontSize:jsonClient.n_placa?'12px':'15px',
                                    fontWeight:jsonClient.n_placa?'700':'400',
                                    color:jsonClient.n_placa?'rgba(0,0,0,0.9)':'rgba(0,0,0,0.5)',
                                    }}>Nº Placa</label>
                                <input
                                id = 'n_placa'
                                value={jsonClient.n_placa}
                                onChange={(e)=>{handleChange(e)}}
                                type='text'></input>
                            </div>

                            <div className='inputStyle'>
                                <label style={{
                                    transition:'all 0.25s ease-in-out',
                                    top:jsonClient.tp_veiculo?'-13px':'3px',
                                    left:jsonClient.tp_veiculo?'0px':'8px',
                                    fontSize:jsonClient.tp_veiculo?'12px':'15px',
                                    fontWeight:jsonClient.tp_veiculo?'700':'400',
                                    color:jsonClient.tp_veiculo?'rgba(0,0,0,0.9)':'rgba(0,0,0,0.5)',
                                    }}>Tipo</label>
                                <select
                                id = 'tp_veiculo'
                                value={jsonClient.tp_veiculo}
                                onChange={(e)=>{handleChange(e)}}
                                type='text'>
                                    <option></option>
                                    <option>CARRO</option>
                                    <option>MOTO</option>
                                    <option>CAMINHÃO</option>
                                    <option>VAN</option>

                                </select>
                            </div>
                        </fieldset>


                        <fieldset>
                            <legend>Dados Rastreador</legend>
                            <div className='inputStyle'>
                                <label style={{
                                    transition:'all 0.25s ease-in-out',
                                    top:jsonClient.equipamento?'-13px':'3px',
                                    left:jsonClient.equipamento?'0px':'8px',
                                    fontSize:jsonClient.equipamento?'12px':'15px',
                                    fontWeight:jsonClient.equipamento?'700':'400',
                                    color:jsonClient.equipamento?'rgba(0,0,0,0.9)':'rgba(0,0,0,0.5)',
                                    }}>Equip.</label>
                                <input
                                id = 'equipamento'
                                value={jsonClient.equipamento}
                                onChange={(e)=>{handleChange(e)}}
                                type='text'></input>
                            </div>

                            <div className='inputStyle'>
                                <label style={{
                                    transition:'all 0.25s ease-in-out',
                                    top:jsonClient.imei?'-13px':'3px',
                                    left:jsonClient.imei?'0px':'8px',
                                    fontSize:jsonClient.imei?'12px':'15px',
                                    fontWeight:jsonClient.imei?'700':'400',
                                    color:jsonClient.imei?'rgba(0,0,0,0.9)':'rgba(0,0,0,0.5)',
                                    }}>Nº Imei</label>
                                <input
                                id = 'imei'
                                value={jsonClient.imei}
                                onChange={(e)=>{handleChange(e)}}
                                type='text'></input>
                            </div>

                            <div className='inputStyle'>
                                <label style={{
                                    transition:'all 0.25s ease-in-out',
                                    top:jsonClient.numero_sim?'-13px':'3px',
                                    left:jsonClient.numero_sim?'0px':'8px',
                                    fontSize:jsonClient.numero_sim?'12px':'15px',
                                    fontWeight:jsonClient.numero_sim?'700':'400',
                                    color:jsonClient.numero_sim?'rgba(0,0,0,0.9)':'rgba(0,0,0,0.5)',
                                    }}>Nº Sim</label>
                                <input
                                id = 'numero_sim'
                                value={jsonClient.numero_sim}
                                onChange={(e)=>{handleChange(e)}}
                                type='text'></input>
                            </div>

                            <div className='inputStyle'>
                                <label style={{
                                    transition:'all 0.25s ease-in-out',
                                    top:jsonClient.data_Instalacao?'-13px':'3px',
                                    left:jsonClient.data_Instalacao?'0px':'8px',
                                    fontSize:jsonClient.data_Instalacao?'12px':'15px',
                                    fontWeight:jsonClient.data_Instalacao?'700':'400',
                                    color:jsonClient.data_Instalacao?'rgba(0,0,0,0.9)':'rgba(0,0,0,0.5)',
                                    }}>Instalação</label>
                                <input
                              
                                id = 'data_Instalacao'
                                value={jsonClient.data_Instalacao}
                                onChange={(e)=>{handleChange(e)}}
                                type='date'></input>
                            </div>
                        </fieldset>


                        <fieldset>
                            <legend>Dados Contrato</legend>
                            <div className='inputStyle'>
                                <label style={{
                                    transition:'all 0.25s ease-in-out',
                                    top:jsonClient.valor?'-13px':'3px',
                                    left:jsonClient.valor?'0px':'8px',
                                    fontSize:jsonClient.valor?'12px':'15px',
                                    fontWeight:jsonClient.valor?'700':'400',
                                    color:jsonClient.valor?'rgba(0,0,0,0.9)':'rgba(0,0,0,0.5)',
                                    }}>Valor</label>
                                <input
                                id = 'valor'
                                value={jsonClient.valor}
                                onChange={(e)=>{handleChange(e)}}
                                type='text'></input>
                            </div>

                            <div className='inputStyle'>
                                <label style={{
                                    transition:'all 0.25s ease-in-out',
                                    top:jsonClient.tipo_aquisicao?'-13px':'3px',
                                    left:jsonClient.tipo_aquisicao?'0px':'8px',
                                    fontSize:jsonClient.tipo_aquisicao?'12px':'15px',
                                    fontWeight:jsonClient.tipo_aquisicao?'700':'400',
                                    color:jsonClient.tipo_aquisicao?'rgba(0,0,0,0.9)':'rgba(0,0,0,0.5)',
                                    }}>Contrato</label>
                                <select
                                id = 'tipo_aquisicao'
                                value={jsonClient.tipo_aquisicao}
                                onChange={(e)=>{handleChange(e)}}
                                type='text'>
                                    <option></option>
                                    <option>Comodato</option>
                                    <option>Aquisição</option>

                                </select>
                            </div>

                            <div className='inputStyle'>
                                <label style={{
                                    transition:'all 0.25s ease-in-out',
                                    top:jsonClient.vencimento?'-13px':'3px',
                                    left:jsonClient.vencimento?'0px':'8px',
                                    fontSize:jsonClient.vencimento?'12px':'15px',
                                    fontWeight:jsonClient.vencimento?'700':'400',
                                    color:jsonClient.vencimento?'rgba(0,0,0,0.9)':'rgba(0,0,0,0.5)',
                                    }}>Vencimento</label>
                                <select
                                id = 'vencimento'
                                value={jsonClient.vencimento}
                                onChange={(e)=>{handleChange(e)}}
                                type='text'>
                                    <option></option>
                                    <option>05</option>
                                    <option>10</option>
                                    <option>15</option>

                                </select>
                            </div>

                            <div className='inputStyle'>
                                <label style={{
                                    transition:'all 0.25s ease-in-out',
                                    top:jsonClient.obs?'-13px':'3px',
                                    left:jsonClient.obs?'0px':'8px',
                                    fontSize:jsonClient.obs?'12px':'15px',
                                    fontWeight:jsonClient.obs?'700':'400',
                                    color:jsonClient.obs?'rgba(0,0,0,0.9)':'rgba(0,0,0,0.5)'
                                    }}>Observações</label>
                                <textarea
                                style={{outline:'none'}}
                                id = 'obs'
                                value={jsonClient.obs}
                                onChange={(e)=>{handleChange(e)}}
                                type='date'></textarea>
                            </div>
                        </fieldset>

                       <div style={{width:'100%', boxSizing:'border-box',
                    display:'flex', justifyContent:'flex-end', marginBottom:'10px'}}>
                       <button className='RegistrarBtn'
                       onClick={(e)=>{handleRegistrar(e)}}
                       >
                        Registrar
                    </button>
                       </div>
                    
                    </form>
                </div>
            </div>
        </>
    )
}



export default ComponenteAddCliente